
import { Component, Inject, Vue } from "vue-property-decorator";
import { DepositEntity } from "../../entities/deposit.entity";
import gql from "graphql-tag";
import { API_ENDPOINT_SYMBOL } from "@/constants";
import { usePaginate } from "@/graphql/use-paginate";
import { DepositFragment } from "@/graphql/fragments";

@Component({
  apollo: {
    deposits: {
      update(data) {
        this.depositsCount = data.paginateDeposit
          ? data.paginateDeposit.totalDocs
          : 0;
        return data.paginateDeposit
          ? data.paginateDeposit.docs.map((item) => new DepositEntity(item))
          : null;
      },
      query: usePaginate("Deposit", DepositFragment),
      skip() {
        return !Object.keys(this.depositsTableOptions).length;
      },
      variables() {
        return {
          options: {
            sort: "-_id",
            page: this.depositsTableOptions.page,
            limit: this.depositsTableOptions.itemsPerPage,
          },
        };
      },
    },
  },
})
export default class DepositHistory extends Vue {
  @Inject(API_ENDPOINT_SYMBOL) apiEndpoint!: string;
  public deposits: DepositEntity[] | null = null;
  public depositsCount = 0;
  public depositsTableOptions: {
    sortBy: string[];
    sortDesc: boolean[];
    page: number;
    itemsPerPage: number;
  } = {} as any;
}
