import { WithdrawPaymentSystemEntity } from "@/entities/WithdrawPaymentSystem";

const STATE_LABELS = {
  FINISHED: "Зачислен",
  CREATED: "В процессе",
  FAILED: "Отклонен",
  MODERATION: "В процессе",
};

export class WithdrawalEntity {
  _id!: string;
  giveAmount!: number;
  state!: "FINISHED" | "CREATED" | "FAILED";
  getAmount!: number;
  paymentSystem!: WithdrawPaymentSystemEntity;

  constructor(opts: any) {
    Object.assign(this, opts);
    this.paymentSystem = new WithdrawPaymentSystemEntity({
      _id: opts.paymentSystem,
    });
  }

  get stateLabel() {
    return STATE_LABELS[this.state];
  }
}
