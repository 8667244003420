
import {
  Component,
  InjectReactive,
  Prop,
  VModel,
  Vue,
} from "vue-property-decorator";
import { UserItemEntity } from "@/entities/user-item.entity";
import { USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import { AuthUserFragment } from "@/graphql/fragments";
import gql from "graphql-tag";
import * as sumBy from "lodash/sumBy";
import Price from "@/components/base/Price.vue";

@Component({
  components: {
    Price,
  },
})
export default class WithdrawItemsDialog extends Vue {
  @VModel({ type: Boolean }) show!: boolean;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity | null;
  @Prop() items!: UserItemEntity[];
  private updatingTradeUrl = false;
  private loading = false;
  private tradeUrl = "";

  get itemsPrice() {
    return Number(sumBy(this.items, "price").toFixed(2));
  }

  async createSkinsWithdraw() {
    this.loading = true;
    const withdrawals: any[] | Error = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: CreateWithdrawalInput!) {
            createSkinsWithdrawal(input: $input) {
              _id
              state
              itemIds
            }
          }
        `,
        variables: {
          input: {
            itemIds: this.items.map(({ _id }) => _id),
          },
        },
      })
      .then(({ data: { createSkinsWithdrawal } }) => createSkinsWithdrawal)
      .catch((e) => e);

    if (withdrawals instanceof Error) {
      let text = `Во время создания предложения обмена произошла ошибка: "${withdrawals.message
        .replace("GraphQL error: ValidationException: ", "")
        .replace("GraphQL error: ", "")
        .replace(
          "There is an error while creating the offer",
          "Произошла неизвестная ошибка, пожалуйста, обратитесь в поддержку (https://t.me/buyskins_support)"
        )
        .replace(
          "Trade url must belong to your Steam account",
          "Вывод разрешен только на профиль Stream, через который вы авторизованы"
        )
        .replace(
          "Forbidden resource",
          "Вывод запрещен, обратитесь в поддержку"
        )}"`;

      if (
        withdrawals.message.indexOf(`some of steamBotItemsIds don't exist`) !==
        -1
      ) {
        text = `К сожалению, мы не нашли некоторые предметы. Советуем Вам попробовать еще раз через пару минут, но, если ошибка повторяется, продайте помеченные предмет(ы), либо обратитесь в поддержку.`;
        this.$emit("refresh");
      }

      if (
        withdrawals.message.indexOf(
          `Partner inventory privacy is set to "Private"`
        ) !== -1 ||
        withdrawals.message.indexOf(
          `There was an error sending your trade offer.  Please try again later. (15)`
        ) !== -1
      ) {
        text = `Ваш аккаунт не доступен для обмена. Попробуйте отправить кому-нибудь трейд и узнаете причину.`;
        this.$emit("refresh");
      }

      this.$notify({
        type: "error",
        text,
      });
    } else if (
      withdrawals.length === 1 &&
      withdrawals[0].state === "MODERATION"
    ) {
      this.$notify({
        type: "info",
        text: `К сожалению, Ваши действия показались нам подозрительными, мы отправили Ваш вывод на модерацию. Пожалуйста, свяжитесь с нами через <a href="/support#open-support-dialog">поддержку</a>.`,
      });

      this.$emit("success");
    } else {
      const itemsCount = withdrawals.reduce(
        (acc, { itemIds }) => acc + itemIds.length,
        0
      );

      this.$notify({
        type: "info",
        text: `Предметы успешно отправлены, Вы можете принять их по <a href="https://steamcommunity.com/id/me/tradeoffers/" target="_blank">ссылке</a>.${
          itemsCount === this.items.length
            ? ""
            : " Некоторые предметы не удалось вывести, и они были возвращены в Ваш инвентарь."
        }`,
      });

      this.$emit("success");
    }

    this.loading = false;
    this.show = false;
  }

  async updateTradeUrl() {
    this.updatingTradeUrl = true;

    await this.$apollo.mutate({
      mutation: gql`
        mutation ($input: UpdateUserInput!) {
          updateUser(input: $input) {
            ...AuthUser
          }
        }
        ${AuthUserFragment}
      `,
      variables: {
        input: {
          tradeUrl: this.tradeUrl,
        },
      },
    });

    this.updatingTradeUrl = false;
  }
}
