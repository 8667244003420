import { DepositPaymentSystemEntity } from "@/entities/deposit-payment-system.entity";

const STATE_LABELS = {
  FINISHED: "Зачислен",
  CREATED: "Ожидает оплаты",
  FAILED: "Отклонен",
};

export class DepositEntity {
  _id!: string;
  giveAmount!: number;
  getAmount!: number;
  state!: "FINISHED" | "CREATED" | "FAILED";
  paymentSystem!: DepositPaymentSystemEntity;

  constructor(opts: any) {
    Object.assign(this, opts);
    this.paymentSystem = new DepositPaymentSystemEntity({
      _id: opts.paymentSystem,
    });
  }

  get stateLabel() {
    return STATE_LABELS[this.state];
  }
}
