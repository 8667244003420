
import { Component, Vue } from "vue-property-decorator";
import { WithdrawalEntity } from "../../entities/withdrawal.entity";
import gql from "graphql-tag";
import { usePaginate } from "@/graphql/use-paginate";
import { DepositFragment, WithdrawalFragment } from "@/graphql/fragments";

@Component({
  apollo: {
    withdrawals: {
      update(data) {
        this.withdrawalsCount = data.paginateWithdrawal
          ? data.paginateWithdrawal.totalDocs
          : 0;

        return data.paginateWithdrawal
          ? data.paginateWithdrawal.docs.map(
              (item) => new WithdrawalEntity(item)
            )
          : null;
      },
      query: usePaginate("Withdrawal", WithdrawalFragment),
      skip() {
        return !Object.keys(this.withdrawalsTableOptions).length;
      },
      variables() {
        return {
          options: {
            sort: "-_id",
            page: this.withdrawalsTableOptions.page,
            limit: this.withdrawalsTableOptions.itemsPerPage,
          },
        };
      },
    },
  },
})
export default class WithdrawHistory extends Vue {
  public withdrawals: WithdrawalEntity[] | null = null;
  public withdrawalsCount = 0;
  public withdrawalsTableOptions: {
    sortBy: string[];
    sortDesc: boolean[];
    page: number;
    itemsPerPage: number;
  } = {} as any;
}
