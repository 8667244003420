import qiwiImg from "@/assets/deposits/qiwi.png";
import cardRubImg from "@/assets/deposits/card-rub.png";
import steamImg from "@/assets/deposits/steam.png";
import cardImg from "@/assets/deposits/card.png";
import usdtImg from "@/assets/deposits/usdt.png";
import sbpImg from "@/assets/deposits/sbp.svg";

export const PaymentSystemData = {
  BALANCE_RUB: {
    label: "Баланс сайта",
    image: cardRubImg,
  },
  BALANCE_COMPENSATION_RUB: {
    label: "Компенсация",
    image: cardRubImg,
  },
  SKINSPLUS_RUB: {
    label: "Вывод скинов",
    image: steamImg,
    hidden: true,
  },

  PAYPALYCH_CARD_RUB: {
    label: "Банковская карта (RUB)",
    image: cardRubImg,
    recipient: {
      mask: "####-####-####-####",
      placeholder: "XXXX-XXXX-XXXX-XXXX",
      rule: /^\d{4}-?\d{4}-?\d{4}-?\d{4}$/,
      replaceRule: /-/g,
    },
  },

  SKY_CARD_RUB: {
    label: "Банковская карта РФ (RUB) #2",
    image: cardRubImg,
    recipient: {
      mask: "####-####-####-####",
      placeholder: "XXXX-XXXX-XXXX-XXXX",
      rule: /^\d{4}-?\d{4}-?\d{4}-?\d{4}$/,
      replaceRule: /-/g,
    },
  },

  PAYPALYCH_SBP_RUB: {
    label: "Вывод СБП",
    image: sbpImg,
    recipient: {
      mask: "+#############",
      placeholder: "+7XXXXXXXXXX",
      rule: /^\+?(7)\d{10}$/,
      replaceRule: undefined,
    },
  },
  PAYPALYCH_QIWI_RUB: {
    label: "QIWI кошелек",
    image: qiwiImg,
    recipient: {
      mask: "+#############",
      placeholder: "+XXXXXXXXXXX",
      rule: /^\+?(375|7|380|99|373)\d{9,12}$/,
      replaceRule: undefined,
    },
  },
  ENOT_CARD_RUB: {
    label: "Банковская карта (RUB)",
    image: cardRubImg,
    recipient: {
      mask: "####-####-####-####",
      placeholder: "XXXX-XXXX-XXXX-XXXX",
      rule: /^\d{4}-?\d{4}-?\d{4}-?\d{4}$/,
      replaceRule: /-/g,
    },
  },
  ENOT_QIWI_RUB: {
    label: "QIWI кошелек",
    image: qiwiImg,
    recipient: {
      mask: "+#############",
      placeholder: "+XXXXXXXXXXX",
      rule: /^\+?(375|7|380|99|373)\d{9,12}$/,
      replaceRule: /[^\d]/g,
    },
  },
  OBMENKA_CARD_RUB: {
    label: "Банковская карта (RUB)",
    image: cardRubImg,
    recipient: {
      mask: "####-####-####-####",
      placeholder: "XXXX-XXXX-XXXX-XXXX",
      rule: /^\d{4}-?\d{4}-?\d{4}-?\d{4}$/,
      replaceRule: /-/g,
    },
  },
  OBMENKA_CARD_UAH: {
    label: "Банковская карта (UAH)",
    image: cardImg,
    recipient: {
      mask: "####-####-####-####",
      placeholder: "XXXX-XXXX-XXXX-XXXX",
      rule: /^\d{4}-?\d{4}-?\d{4}-?\d{4}$/,
      replaceRule: /-/g,
    },
  },
  OBMENKA_QIWI_RUB: {
    label: "QIWI кошелек",
    image: qiwiImg,
    recipient: {
      mask: "+#############",
      placeholder: "+XXXXXXXXXXX",
      rule: /^\+?(375|7|380|99|373)\d{9,12}$/,
      replaceRule: /[^\d]/g,
    },
  },
  ALIKASSA_QIWI_RUB: {
    label: "QIWI кошелек",
    image: qiwiImg,
    recipient: {
      mask: "+#############",
      placeholder: "+XXXXXXXXXXX",
      rule: /^\+?(375|7|380|99|373)\d{9,12}$/,
      replaceRule: /[^\d]/g,
    },
  },

  CRYPTO_PAYMENTS_TRC20_USDT: {
    label: "USDT (TRC20)",
    image: usdtImg,
    recipient: {
      placeholder: "Номер кошелька TRC20",
      rule: /^T[A-Za-z1-9]{33}$/,
    },
  },
  CRYPTO_PAYMENTS_ERC20_USDT: {
    label: "USDT (ERC20)",
    image: usdtImg,
    recipient: {
      placeholder: "Номер кошелька ERC20",
      rule: /^0x[a-fA-F0-9]{40}$/,
    },
  },

  OTHER_RUB: { label: "Вывод деньгами", image: cardImg },
  ADMIN_RUB: { label: "Пополнение администрацией", image: cardImg },
};

export class WithdrawPaymentSystemEntity {
  _id!: string;
  commission!: string;
  minGetAmount!: number;
  image!: string;
  label!: string;
  hidden?: boolean;
  recipient?: {
    replaceRule: RegExp;
    rule: RegExp;
    placeholder: string;
    mask: string;
  };

  constructor(opts: any) {
    Object.assign(this, opts);
    Object.keys(PaymentSystemData).map((key) => {
      if (this._id.indexOf(key) !== -1) {
        Object.assign(this, PaymentSystemData[key]);
      }
    });
  }
}
