
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import gql from "graphql-tag";
import { WithdrawPaymentSystemEntity } from "@/entities/WithdrawPaymentSystem";
import GradientBtn from "@/components/base/GradientBtn.vue";
import * as sumBy from "lodash/sumBy";
import { UserItemEntity } from "@/entities/user-item.entity";

@Component({
  components: {
    GradientBtn,
  },
  apollo: {
    getAmount: {
      query: gql`
        query (
          $giveAmount: Float!
          $paymentSystem: WithdrawMoneyPaymentSystemEnum!
        ) {
          calculateWithdrawGetAmount(
            giveAmount: $giveAmount
            paymentSystem: $paymentSystem
          )
        }
      `,
      skip() {
        return true;
      },
      variables() {
        return {
          paymentSystem: this.selectedPaymentSystem._id,
          giveAmount: this.giveAmount || 0,
        };
      },
      update(data) {
        return typeof data.calculateWithdrawGetAmount === "number"
          ? data.calculateWithdrawGetAmount
          : null;
      },
    },
    paymentSystems: {
      update(data) {
        const paymentSystems = data.withdrawPaymentSystems
          ? data.withdrawPaymentSystems.map(
              (value) => new WithdrawPaymentSystemEntity(value)
            )
          : null;

        if (paymentSystems) {
          this.selectedPaymentSystem = paymentSystems[0];
        }

        return paymentSystems;
      },
      variables() {
        return {
          giveAmount: this.giveAmount,
        };
      },
      query: gql`
        query ($giveAmount: Float) {
          withdrawPaymentSystems(giveAmount: $giveAmount) {
            _id
            commission
            minGetAmount
          }
        }
      `,
    },
  },
})
export default class WithdrawContent extends Vue {
  paymentSystems: WithdrawPaymentSystemEntity[] | null = null;
  selectedPaymentSystem: WithdrawPaymentSystemEntity | null = null;
  getAmount: number | null = null;
  recipientVal = "";
  sbpId: { _id: string; label: string } | null = null;
  loading = false;
  @Ref("form") form: any;
  @Prop() items!: UserItemEntity[];

  get giveAmount() {
    return Number(sumBy(this.items, "price").toFixed(2));
  }

  get recipient() {
    return this.selectedPaymentSystem &&
      this.selectedPaymentSystem.recipient &&
      this.recipientVal
      ? this.recipientVal.replace(
          this.selectedPaymentSystem.recipient.replaceRule,
          ""
        )
      : this.recipientVal;
  }

  get sbpBankId() {
    return this.selectedPaymentSystem && this.sbpId && this.sbpId._id;
  }

  get isRecipientRight() {
    return this.selectedPaymentSystem
      ? this.selectedPaymentSystem.recipient
        ? this.selectedPaymentSystem.recipient.rule.test(this.recipientVal)
        : true
      : false;
  }

  @Watch("selectedPaymentSystem", {})
  async selectedPaymentSystemWatcher() {
    await this.$nextTick();
    await this.calculateGetAmount();
    await this.$nextTick();
    this.form.validate();
    await this.$nextTick();
    this.recipientVal = "";
    this.sbpId = null;
  }

  async calculateGetAmount() {
    return this.$apollo.queries.getAmount.start();
  }

  async createMoneyWithdraw() {
    this.loading = true;

    let withdrawal: { _id: string } | Error = await this.$apollo
      .mutate({
        mutation: gql`
          mutation ($input: CreateMoneyWithdrawalInput!) {
            createMoneyWithdrawal(input: $input) {
              _id
            }
          }
        `,
        variables: {
          input: {
            paymentSystem: this.selectedPaymentSystem!._id,
            itemIds: this.items.map(({ _id }) => _id),
            recipient: this.recipient,
            ...(this.sbpBankId ? { sbpId: this.sbpBankId } : {}),
          },
        },
      })
      .then(({ data }) => data.createMoneyWithdrawal)
      .catch((e) => e);

    this.loading = false;

    if (withdrawal instanceof Error) {
      this.$emit("error", withdrawal);
    } else {
      this.$emit("success", withdrawal);
    }
  }
}
