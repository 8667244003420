
import {
  Component,
  Inject,
  InjectReactive,
  Vue,
  Watch,
} from "vue-property-decorator";
import GradientBtn from "@/components/base/GradientBtn.vue";
import Price from "@/components/base/Price.vue";
import BorderContainer from "@/components/base/BorderContainer.vue";
import { IS_MOBILE_SYMBOL, USER_SYMBOL } from "@/constants";
import { AuthUserEntity } from "@/entities/user.entity";
import ItemList from "@/components/items/ItemList.vue";
import gql from "graphql-tag";
import ScrollFetchMore from "@/components/base/ScrollFetchMore.vue";
import {
  AccountFragment,
  AuthUserFragment,
  UserItemWithFromDocumentFragment,
} from "@/graphql/fragments";
import { UserItemEntity } from "@/entities/user-item.entity";
import { PaginateUserItemWithFromDocumentQuery } from "@/graphql/queries";
import Item from "@/components/items/Item.vue";
import WithdrawItemsDialog from "@/components/profile/WithdrawItemsDialog.vue";
import WithdrawMoney from "@/components/profile/WithdrawMoney.vue";
import AccountList from "@/components/accounts/AccountList.vue";
import { AccountEntity } from "@/entities/account.entity";
import DepositHistory from "@/components/deposit/DepositHistory.vue";
import WithdrawHistory from "@/components/withdraw/WithdrawHistory.vue";
import TopAppBar from "@/components/base/TopAppBar.vue";
import moment from "moment";
import { useScroll } from "@/graphql/use-scroll";
import { mongoTextSearch } from "@/helpers/mongo-text-search.helper";

@Component({
  components: {
    TopAppBar,
    DepositHistory,
    WithdrawHistory,
    AccountList,
    WithdrawMoney,
    WithdrawItemsDialog,
    ItemList,
    Item,
    ScrollFetchMore,
    GradientBtn,
    BorderContainer,
    Price,
  },
  metaInfo: {
    title: "Профиль",
  },
  apollo: {
    accounts: {
      update(data) {
        return data.scrollAccount
          ? data.scrollAccount.docs.map((item) => new AccountEntity(item))
          : null;
      },
      fetchPolicy: "cache-and-network",
      query: useScroll("Account", AccountFragment),
      variables() {
        return {
          filter: {
            userId: { eq: this.user?._id },
          },
          options: {
            limit: 10,
            sort: this.accountsSortBy,
          },
        };
      },
    },

    lastDepositsGetAmount: {
      update(data) {
        return data.lastDepositsGetAmount || null;
      },
      query: gql`
        query {
          lastDepositsGetAmount
        }
      `,
    },
    items: {
      update(data) {
        return data.scrollUserItem
          ? data.scrollUserItem.docs.map((item) => new UserItemEntity(item))
          : null;
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.user;
      },
      query: useScroll("UserItem", UserItemWithFromDocumentFragment),
      variables() {
        return {
          options: {
            sort: this.sortBy,
            populate: "fromDocument",
          },
          filter: {
            ...(this.searchText
              ? { _text: { search: mongoTextSearch(this.searchText) } }
              : {}),
            userId: { eq: this.user?._id },
            state: { eq: this.state },
          },
        };
      },
    },
  },
})
export default class Profile extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @InjectReactive(USER_SYMBOL) user!: AuthUserEntity;
  private tradeUrl = "";
  private searchText = "";
  private sortBy = "-price";
  private accountsSortBy = "-price";
  private state = "DEFAULT";
  private selectedItems: UserItemEntity[] = [];
  private items: UserItemEntity[] | null = null;
  private updatingTradeUrl = false;
  private showWithdrawItemsDialog = false;
  private showWithdrawMoneyDialog = false;
  private accounts: AccountEntity[] | null = null;
  private lastDepositsGetAmount: number[] | null = null;

  get itemListActionsSlot() {
    return this.$vuetify.breakpoint.mdAndDown
      ? "actions-append"
      : "actions-prepend";
  }

  @Watch(`$route.hash`)
  @Watch(`$apollo.queries.items.loading`, { immediate: true })
  async watchRouteHash() {
    await this.$nextTick();

    if (this.$route.hash) {
      this.$vuetify.goTo(this.$route.hash.replace("#", "."));
    }
  }

  @Watch("state")
  stateWatcher() {
    this.selectedItems = [];
  }

  withdrawRefresh() {
    this.$apollo.queries.items.refetch();
  }

  withdrawSuccess() {
    this.selectedItems = [];
    this.$apollo.queries.items.refetch();
  }

  async updateTradeUrl() {
    this.updatingTradeUrl = true;

    await this.$apollo.mutate({
      mutation: gql`
        mutation ($input: UpdateUserInput!) {
          updateUser(input: $input) {
            ...AuthUser
          }
        }
        ${AuthUserFragment}
      `,
      variables: {
        input: {
          tradeUrl: this.tradeUrl,
        },
      },
    });

    this.updatingTradeUrl = false;
  }

  created() {
    if (!this.user) {
      this.$router.replace("/#login");
    }

    if (this.user && this.user.tradeUrl) {
      this.tradeUrl = this.user.tradeUrl;
    }
  }

  select(item: UserItemEntity) {
    const index = this.selectedItems.findIndex(
      (item1) => item1._id === item._id
    );

    if (index === -1) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  dateLimit() {
    return moment(this.user.createdAt).format("YYYY");
  }
}
