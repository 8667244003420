
import { Component, Inject, Prop, VModel, Vue } from "vue-property-decorator";
import WithdrawMoneyBottomSheet from "@/components/profile/WithdrawMoneyBottomSheet.vue";
import WithdrawMoneyDialog from "@/components/profile/WithdrawMoneyDialog.vue";
import { UserItemEntity } from "@/entities/user-item.entity";
import { IS_MOBILE_SYMBOL } from "@/constants";

@Component({
  components: {
    WithdrawMoneyDialog,
    WithdrawMoneyBottomSheet,
  },
})
export default class WithdrawMoney extends Vue {
  @Inject(IS_MOBILE_SYMBOL) isMobile!: boolean;
  @VModel({ type: Boolean }) show!: boolean;
  @Prop() items!: UserItemEntity[];

  onSuccess(withdrawal: { _id: string }) {
    this.$emit("success", withdrawal);
    this.$notify({
      type: "success",
      text: `Заявка на вывод создана. Вывод может обрабатываться в течении 4-х часов. В ночное время, вывод может обрабатываться дольше.`,
    });
    this.show = false;
  }

  onError(error: Error) {
    this.show = false;
    this.$notify({
      type: "error",
      text: `Не удалось обработать вывод средств. Произошла ошибка: "${error.message
        .replace("GraphQL error: ValidationException: ", "")
        .replace("GraphQL error: ", "")
        .replace(
          "Forbidden resource",
          "Вывод запрещен, обратитесь в поддержку"
        )}"`,
    });
  }
}
