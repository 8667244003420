import gql from "graphql-tag";

export function usePaginate(modelName: string, fragment: any) {
  const fragmentName = fragment.definitions[0].name.value;

  return gql`
        query($filter: MongooseFilterDto${modelName}, $options: MongoosePaginateOptionsDto){
            paginate${modelName}(filter: $filter, options: $options){
                limit
                page
                skip
                sort
                totalDocs
                totalPages
                docs{
                ...${fragmentName}
                }
            }
        }
        ${fragment}
    `;
}
