
import { Component, Prop, Vue } from "vue-property-decorator";
import Account from "@/components/accounts/Account.vue";
import BaseList from "@/components/base/BaseList.vue";
import { AccountEntity } from "@/entities/account.entity";

@Component({
  components: {
    Account,
    BaseList,
  },
})
export default class AccountList extends Vue {
  @Prop() value!: AccountEntity[];
  @Prop() scroll!: boolean | null;
  @Prop() scrollId!: string | null;
  @Prop({ default: "-price" }) sortBy!: string;
  @Prop({ default: "400px" }) scrollHeight!: number;

  get colsMap() {
    return [
      [1300, 7],
      [900, 6],
      [600, 5],
      [450, 4],
      [400, 3],
      [200, 2],
      [0, 1],
    ];
  }
}
