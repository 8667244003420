var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',_vm._b({attrs:{"items":_vm.withdrawals || [],"headers":[
    {
      text: 'ID',
      align: 'start',
      sortable: false,
      value: '_id',
      width: 100,
      cellClass: 'text-truncate id-cell',
    },
    { text: 'Отдали', sortable: false, value: 'giveAmount' },
    { text: 'Получили', sortable: false, value: 'getAmount' },
    {
      text: 'Платежная система',
      sortable: false,
      value: 'paymentSystem.label',
    },
    { text: 'Статус', sortable: false, value: 'stateLabel', align: 'end' },
  ],"loading":!_vm.withdrawals,"item-key":"_id","items-per-page":5,"footer-props":{
    itemsPerPageOptions: [5, 10, 15, 20, 25],
    itemsPerPageText: 'Выводов на странице',
  },"options":_vm.withdrawalsTableOptions,"server-items-length":_vm.withdrawalsCount},on:{"update:options":function($event){_vm.withdrawalsTableOptions=$event}},scopedSlots:_vm._u([{key:"item.giveAmount",fn:function({ value }){return [_vm._v(" "+_vm._s(value || "-")+" ")]}},{key:"item.getAmount",fn:function({ value }){return [_vm._v(" "+_vm._s(value || "-")+" ")]}},{key:"item.stateLabel",fn:function({ value }){return [_vm._v(" "+_vm._s(value)+" ")]}}])},'v-data-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }