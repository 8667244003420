
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import WithdrawMoneyContent from "@/components/profile/WithdrawMoneyContent.vue";
import { UserItemEntity } from "@/entities/user-item.entity";

@Component({
  components: {
    WithdrawMoneyContent,
  },
})
export default class WithdrawMoneyDialog extends Vue {
  @VModel({ type: Boolean }) show!: boolean;
  @Prop() items!: UserItemEntity[];
}
